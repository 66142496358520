.intro-screen {
  background-image: url('../src/images/wave\ dimensions.svg');
  background-size: cover;
  background-position: bottom;
  height: 100vh;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(3, 1fr);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
  justify-items: center;
}

.logo {
  grid-column: 2;
  grid-row: 6;
  justify-self: center;
  width: 600px;
  height: auto !important;
}

.paragraph1 {
  grid-column: 2;
  font-size: 1.5rem;
  grid-row: 7;
  width: 80%vw;
  text-align: center;
  
}

.paragraph1 * {
color: #333d4e} /* the color of "Minimal, meditative writing app" */

.button-row {
  grid-row: 8;
  grid-column: 2;
  display: flex;
  align-items: center; /* Vertically center align items */
  justify-content: center; /* Horizontally center align items */
}

.custom-button {
  margin: 0 5px;
  font-size: 1rem;
}


.commands-button {
  border: 1px solid #b4d0e1 !important; /* Add border style */
  transition: background-color 0.3s ease; /* Add transition for smooth color change */
}

.custom-button:nth-child(1) {
  background-color: #fff8f6;
  color: rgb(0, 0, 0);
}

.custom-button:nth-child(2) {
  background-color:#fff8f6; 
  color: rgb(0, 0, 0);
}

.custom-button:nth-child(1):hover {
  background-color: #78c0e9;
  color: #fff8f6;
}

.custom-button:nth-child(2):hover {
  background-color:#78c0e9; 
  color: #fff8f6;
}


.custom-button:nth-child(3) {
  background-color: #78c0e9; /* Pop color */
  color: #fff8f6;
}

.custom-button:nth-child(3):hover {
  background-color: #56ade0; /* Pop color */
  color: #fff8f6;
}

.small-text {
  grid-column: 6;
  width: auto;
  height: auto !important;
  text-align: center;
  font-size: 0.9rem;
  color:#4a596d;
}

/* Media Query for mobile devices */
@media (max-width: 576px) {
  .desktop-only-message {
    display: block;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: red;
  }
  
  .paragraph1 {
    grid-column: 2;
    font-size: 0.8rem;
    grid-row: 7;
    width: 75%;
    text-align: center;
  }

  .logo {
    grid-column: 2;
    grid-row: 6;
    justify-self: center;
    align-self: center;
    width: 300px;
    height: auto !important;
  }
}
