/* Images */
img {
  height: auto;
  width: 51%;
  margin-bottom: 2vh;
}

/* Dark Mode for Images */
body.dark-mode img {
  filter: brightness(0.8);
}

/* Breathing Animation */
@keyframes breathingAnimation {
  0%, 100% {
    transform: scale(0.9);
    opacity: 0.3;
    filter: blur(5px);
  }
  50% {
    transform: scale(1);
    opacity: 0.6;
    filter: blur(5px);
  }
}

/* Alert */
.alert-secondary {
  background: linear-gradient(to right, #95d1ff, #9dd2fa, #9dd2fa); /* Default smoky appearance */
  position: fixed;
  top: 2.4rem;
  right: 2vw;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  animation: breathingAnimation 12s ease-in-out infinite;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Styles for iPads and below */
@media only screen and (max-width: 1024px) {
  .alert-container {
    z-index: 10000 !important;
    grid-row: 2;
    grid-column: 5;
    height: 3.2rem;
    padding-top: 0rem;
    width: 100%;
    position: sticky;
    opacity: 1;
    background-color: #fff8f600 !important; /* Light mode background color */
  }
 
}

/* Dark Mode for Alert */
body.dark-mode .alert-secondary {
  background: linear-gradient(to right, #e0e0e0, #ffffff, #e0e0e0); /* Default smoky appearance */
}

/* Container for Orb */
.alert-container {
  z-index: 10000 !important;
  grid-row: 2;
  grid-column: 5;
  height: 3.2rem;
  padding-top: 0rem;
  width: 100%;
  position: sticky;
  opacity: 1;
  background-color: #fff8f600 !important; /* Light mode background color */
}


/* Drawer */
.drawer {
  margin-bottom: -5vh;
}

/* The Parent of Thoughts Sidebar */
.thoughts-sidebar {
  width: 60vw;
  word-break: normal;
  z-index: 20000 !important;
  overflow: scroll !important;
  background-color: #fff8f6; 


}

body.dark-mode .thoughts-sidebar {
  background-color: #373B40;

}


/* Child of Thoughts Sidebar */
.thoughts-sidebar .editor {
  padding-top: 3.6rem !important;
  padding-left: 8vw !important;
  padding-right: 14vw !important;
  margin-bottom: 0vh !important;
  position: relative !important;


}

/* Dark Mode Drawer Color */
body.dark-mode .thoughts-sidebar .editor {
  background-color: #373B40;
  color: #D9D9D9;
}



/* Link */
.link {
  color: black;
  text-decoration: none;
}

/* Body color when drawer is open */
.MuiBackdrop-root {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff8f6 !important; 

}

/* Body color when drawer is open */
body.dark-mode .MuiBackdrop-root {
  background-color: #373B40 !important; 
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide extra scroll bar */
body.dark-mode *::-webkit-scrollbar {
  display: none;
}
/* Global Font and Color */
* {
  font-family: 'P22 Mackinac', 'Times New Roman', Times, serif;
  color: #333d4e;
}

/* Dark Mode for Global Font and Color */
body.dark-mode * {
  font-family: 'P22 Mackinac', 'Times New Roman', Times, serif;
  color: #D9D9D9;
}


