body {
      max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  background: #fff8f6 !important;
  color: #253145;
  margin: 0;
  font-family: 'P22 Mackinac', "PT Serif", 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

p  {
  color: #333d4e;

}

@font-face {
  font-family: 'P22 Mackinac';
  src: url('../src/Fonts/P22Mackinac-Book_13.otf') format('truetype');
  /* add additional formats if desired */
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: 'P22 Mackinac', "Courier New"
}

body.dark-mode {
  background: #373B40 !important; /* Dark background for the whole app */
  color: #D9D9D9; /* Light text for better readability */
}