/* Common styles for light and dark mode */
.ProseMirror.editor {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 6vh;
  padding-left: 30vw;
  padding-right: 30vw;
  padding-bottom: 10vh;
  scroll-padding-bottom: 10vh;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  z-index: 1000;
  line-height: 1.25rem;
}

/* Styles for iPads and below */
@media only screen and (max-width: 1024px) {
  .ProseMirror.editor {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 6vh;
    padding-left: 15vw;
    padding-right: 20vw;
    padding-bottom: 10vh;
    scroll-padding-bottom: 10vh;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 1000;
    line-height: 1.25rem;
  }
 
}

/* Hides the TipTap outline & changes Caret Color */
.ProseMirror.editor.ProseMirror-focused {
  background-color: transparent !important;
  caret-color: #6c6c6c;
  outline: none; /* Remove the outline */
}



.ProseMirror.editor.dark-mode {
  background-color: #1E1E1E; /* Darker background for editor */
  color: #D6D6D6; /* Lighter text color for readability */
  line-height: 1.25rem;
}

.ProseMirror.editor::before {
  content: '\200b'; /* Insert an empty character */
}
