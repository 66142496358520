.fixed-buttons {
  position: absolute;
  bottom: 20px;
  right: 2vw;
  display: flex;
  flex-direction: column; 
  z-index: 1000000;
}

.fixed-buttons button {
  margin-top: 10px;
  color: rgb(22, 22, 22); /* Text color for buttons */
  background-color:rgba(240, 248, 255, 0);
  border:none;

}

/* Styles for buttons in dark mode */
.fixed-buttons.dark-mode button {
  color: rgb(255, 255, 255); /* Text color for buttons */

}

@media only screen and (max-width: 1024px) {
  .alert-container {
    .fixed-buttons {
      position: absolute;
      bottom: 20px;
      right: 1vw;
      display: flex;
      flex-direction: column; 
      z-index: 1000000;
    }
  }
}
